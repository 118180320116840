import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)/(authenticated)": [4,[2]],
		"/(app)/(authenticated)/events": [~5,[2]],
		"/(app)/(authenticated)/events/new": [~9,[2]],
		"/(app)/(authenticated)/events/[event]": [~6,[2]],
		"/(app)/(authenticated)/events/[event]/export": [7,[2]],
		"/(app)/(authenticated)/events/[event]/message": [8,[2]],
		"/(app)/(authenticated)/locations": [~10,[2]],
		"/(app)/(authenticated)/locations/new": [~13,[2]],
		"/(app)/(authenticated)/locations/[location]": [~11,[2]],
		"/(app)/(authenticated)/locations/[location]/templates/[template]": [~12,[2]],
		"/(app)/(authenticated)/orders": [~14,[2]],
		"/(app)/(authenticated)/orders/new": [~16,[2]],
		"/(app)/(authenticated)/orders/[order]": [~15,[2]],
		"/(app)/(authenticated)/productions": [~17,[2]],
		"/(app)/(authenticated)/productions/new": [~19,[2]],
		"/(app)/(authenticated)/productions/[production]": [~18,[2],[,3]],
		"/(app)/signin": [23],
		"/(app)/(authenticated)/vouchers": [~20,[2]],
		"/(app)/(authenticated)/vouchers/new": [~22,[2]],
		"/(app)/(authenticated)/vouchers/[voucher]": [~21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';